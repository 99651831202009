// Variables

$color-primary: white;
$color-secondary: rgba(0, 0, 0, 0.5);
$color-primary: rgba(0, 0, 0, 0.5);

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	padding: 44px 0;
	background-image: url('https://firebasestorage.googleapis.com/v0/b/portafolio-a8d13.appspot.com/o/Potafolio%2FBackground%2Ftexture-two.jpg?alt=media&token=c837791a-895e-46dd-a2d5-63808b24cb82');
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
		'Helvetica Neue', sans-serif;
}

.container {
	padding: 0 70px;
}

@media (max-width: 500px) {
	.container {
		padding: 0 20px;
	}
}

ul {
	list-style: none;
}

a {
	text-decoration: none;
	color: rgba(0, 0, 0, 0.8);
}

button {
	border: none;
	background-color: transparent;
	outline: none;
}
